<template>
	<div class="container-fluid">
		<div class="card login-card shadow border border-white p-5 m-5 bg-white">
			<b-col class="text-center">
				<img src="../../../../assets/images/mainLogo.png" alt="" class="img-fluid mx-auto d-block" />

				<!-- <strong class="logo-text m-3">EZYGO.APP</strong> -->
			</b-col><br><br>
			<b-col class="text-center mb-5">
				<strong class=" m-3">Public Exam Login</strong>
			</b-col>
			<b-col>
				<b-form-group id="input-group-1" label-for="input-1" description="">
					<label class="required-label" for="">Email:</label>
					<b-form-input :disable="(resentOTPcountDownLeft > 0)" id="input-1" type="email" v-model="email"
						placeholder="Enter Email"></b-form-input>
				</b-form-group>
				<b-row style="justify-content: center;">
					<span v-if="loginStatus == 'senting'">Sent OTP ...</span>
					<div v-else>
						<b-button v-if="(resentOTPcountDownLeft == 0)" :disabled="email == ''" class="btn-success"
							@click="sentOtp">Get OTP</b-button>
						<span class="text-info" v-if="(resentOTPcountDownLeft > 0)">You can resent OTP after
							{{ resentOTPcountDownLeft }} seconds</span>
					</div>
				</b-row>
				<div v-if="loginStatus == 'otp_sent'">
					<b-form-group id="input-group-1" label-for="input-1" description="">
						<label class="required-label" for="">OTP:</label>
						<b-form-input v-model="otp" id="input-1" type="text" placeholder="Enter OTP"></b-form-input>
					</b-form-group>

					<b-row style="justify-content: center;">
						<b-button :disabled="otp == ''" @click="participantLogin"
							class="btn-success shadow-sm ">Login</b-button>
					</b-row>
				</div>
			</b-col>
		</div>
	</div>
</template>
<script>
export default {
	async created() {
		this.email = this.$route.params.email ?? '';
		console.log("email", this.email);
		var publicexamAuthToken = localStorage.getItem("publicexamAuthToken");
		console.log("email", publicexamAuthToken);
		if (publicexamAuthToken) {
			var publicexamAuthEmail = localStorage.getItem("publicexamAuthEmail");
			if (this.email == publicexamAuthEmail) {
				this.$router.push('/publicexam/dashboard');
			} else {
				localStorage.removeItem("publicexamAuthToken");
			}
		}
	},
	data() {
		return {
			email: this.$route.params.email,
			otp: this.$route.params.otp,
			resentOTPcountDownLeft: 0,
			loginStatus: '',
		}
	},
	methods: {
		async participantLogin() {
			const url = `${this.$store.getters.getAPIKey.mainAPI}/publicexampublicuser/login/with_otp`;
			let token = null;
			await this.$axios
				.post(url, { email: this.email, otp: this.otp })
				.then((response) => {
					token = response.data.publicexam_auth_token;
				})
				.catch((error) => {
					let errortxt = error.response.data.message ?? 'Failed to Login, please try agin';
					this.$toast.error(errortxt, {
						position: "top",
						duration: 7000,
					});
				});
			if (token) {
				await localStorage.setItem("publicexamAuthToken", token);
				await localStorage.setItem("publicexamAuthEmail", this.email);
				this.$router.push('/publicexam/dashboard');
			}
		},
		resentOtpCountDown() {
			this.resentOTPcountDownLeft = 120;
			if (this.resentOTPcountDownLeft > 0) {
				setTimeout(() => {
					this.resentOTPcountDownLeft = this.resentOTPcountDownLeft - 1;
					this.resentOtpCountDown()
				}, 1000)
			}
		},
		async sentOtp() {
			this.loginStatus = 'senting';

			const url = `${this.$store.getters.getAPIKey.mainAPI}/publicexampublicuser/login/request_otp`;
			await this.$axios
				.post(url, { email: this.email })
				.then(() => {
					this.$toast.success('OTP sent successfully, please check your inbox.', {
						position: "top",
						duration: 4000,
					});
					this.resentOtpCountDown();
					this.loginStatus = 'otp_sent';
				})
				.catch((error) => {
					this.loginStatus = '';
					let errortxt = error.response.data.message ?? 'Failed to Sent OTP';
					this.$toast.error(errortxt, {
						position: "top",
						duration: 7000,
					});
				});
		},
	},

}

</script>
<style scoped>
button {
	border-radius: 10px;
}

.logo-text {
	font-size: 23px !important;
	color: #4b2354;
}

.container-fluid {
	min-height: 100vh;
	/* background-color: #cdffff !important; */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	background: rgba(93, 84, 240, .5);
	background: linear-gradient(90deg, rgb(150 219 255 / 58%) 0%, rgb(222 136 255 / 54%) 100%);
}

.login-card {
	width: 390px;
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
}
</style>





